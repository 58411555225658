import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import "./index.css";
import PdfViewer from "../PdfViewer/PdfViewer";
import DocxOpener from "../DocxOpener/DocxOpener";
import HeicViewer from "../HeicViewer/HeicViewer";

const CustomFileViewer = (props) => {
  const toggle = () => props.setViewFileModal(!props.viewFileModal);
  let fileType  = null;
  if(props.fileType) {
    if(['jpeg', 'jpg', 'png', 'svg', 'bmp'].includes(props.fileType)) {
      fileType = "image";
    } else if(['docx', 'xlsx'].includes(props.fileType)) {
      fileType = "Xfile";
    } else if(['pdf'].includes(props.fileType)) {
      fileType = "pdf";
    } else if(['mp3', 'm4a', 'aac', 'flac', 'wav'].includes(props.fileType)) {
      fileType = "audio";
    } else if(['mp4', 'avi', 'hevc'].includes(props.fileType)) {
      fileType = "video";
    } else if (["heic", "heif"].includes(props.fileType)) fileType = "heic";
  }
  const fileView = () => {
    switch(fileType) {
      case 'image':
        return <ReactPanZoom
            image={props.filePath}
            alt={props.fileType}
            className='view-image'
          />
        case 'video':
        return <div className='view-video'>
            <ReactPlayer url={props.filePath} controls={true} />
          </div>;
      case 'audio':
        return <div className='view-audio'>
            <ReactAudioPlayer
                        src={props.filePath}
                        autoPlay
                        controls
                    />
          </div>;
      case 'pdf':
        return <div className='view-file'>
            <PdfViewer pdfFileLink={props?.filePath} />
          </div>;
      case 'Xfile':
        return <DocxOpener path={props.filePath} />;
        case 'heic':
            return <HeicViewer selectedDoc={props.filePath}/>
      default:
        return <DocxOpener path={props.filePath} />;
    }
  }
  return (
    <Modal data-testid="CustomFileViewer" isOpen={props.viewFileModal} toggle={toggle} scrollable={true} centered={true} className='file-upload-modal'>
      <ModalHeader data-testid="toggle-button" toggle={toggle}>View uploaded documents</ModalHeader>
      <ModalBody className={fileType === "image" || fileType === "heic" ? "image-modal-body" : "modal-body"}>
        {props.download === true && <div className='download-doc-btn'>
            <div className='download-doc-btn'>
              <button data-testid="CustomFileViewer2" onClick={props.DownloadDocs}>Download</button>
                      </div>
        </div>}
        {fileView()}
      </ModalBody>
    </Modal>
  )
}

export default CustomFileViewer;
