import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import path from "../../constants/pathConstants";
import { connect } from "react-redux";
import { compose } from "redux";
import { imageBaseUrl } from "../../utils/constants";

const ErrorFallbackComponent = () => {
  
  const navigate = useNavigate();
  const loginRedirect = () => {
    navigate(path.MOBILE);
    window.location.reload();
  };
  const homeRedirect = () => {
    navigate(path.DASHBOARD);
    window.location.reload();
  };

  return (
    <div className="fallback-main-component">
      <img
        className="error-fallabck-exclamation-image"
        src={`${imageBaseUrl}/ErrorFallBack.svg`}
        alt="Error Exclamation"
      />
      <h3>Something Went Wrong</h3>
      {!sessionStorage.getItem("isLoggedin") ? (
        <button
          className="error-fallabck-button"
        
        onClick={loginRedirect}>Go to Login page</button>
      ) : (
        <button
          className="error-fallabck-button"
        
        onClick={homeRedirect}>Go to Home page</button>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(ErrorFallbackComponent);
