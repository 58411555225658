import React, { memo } from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { makeSelectCaseSearchValue } from "../../containers/SearchCase/selectors";

const CardField = ({ item, index, searchValue }) => {
  return (
    <Col
      className="card-mb open-details-card"
      style={{
        margin: 0,
        padding: "10px 5px",
      }}
      xs="3"
      key={index}
    >
      <div className="card-lable">{item?.label}</div>
      <div
        className={
          searchValue && item?.value == searchValue
            ? "card-value highlight"
            : "card-value"
        }
      >
        {item?.value ? item?.value : "-"}
      </div>
    </Col>
  );
};

CardField.propTypes = {
  searchValue: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  searchValue: makeSelectCaseSearchValue(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CardField);
