import React, { createContext } from "react";
import API from './api';
import urlConstants from "../constants/urlConstants";
import { useNavigate } from "react-router-dom";

const DataDispatchContext = createContext(undefined);

function DataProvider({ children }) {  
  const navigate = useNavigate();

  API.interceptors.response.use(response => {
    return response;
  }, error => {
    if(error?.response?.data?.message === "Case has been withdrawn" || error?.response?.data?.message === "Invalid Case ID") {
      return Promise.reject({ response: { data: { message: "Case has been withdrawn" } } });
    } else {
      return Promise.reject(error)
    }
  });

  const apiPost = (url, body, header) => {
    return API.post(url, body, header).then(data => {
      if (data.status === 200) {
        return data
      } else {
        logout();
        throw Promise.reject({ response: { data: { message: "You Have been Logged out" } } });
      }
    }).catch(err => {
      if (err.response.status === 403) {
        if (err.response.data.message && err.response.data.message !== "") {
          logout(err.response.data.message)
        } else {
          logout("You Have been Logged out")
        }
      }
      return Promise.reject(err);
    })
  };



  const apiGet = (url, header) => {
    return API.get(url, header).then(data => {
      if (data.status === 200) {
        return data
      } else {
        logout();
        throw Promise.reject({ response: { data: { message: "You Have been Logged out" } } });
      }
    }).catch(err => {
      if (err.response.status === 403) {
        if (err.response.data.message && err.response.data.message !== "") {
          logout(err.response.data.message)
        } else {
          logout("You Have been Logged out")
        }
      }
      return Promise.reject(err);
    })
  };

  const logout = (msg) => {
    apiGet(
      urlConstants.LOGOUT,
      {
        withCredentials: true
      }
    )
    sessionStorage.setItem("isLoggedin", false);
    const param = btoa('err=' + msg);
    if (msg) {
      navigate('/?' + param);
    } else {
      navigate('/');
      }
  }

  const allSetFun = {
    apiPost: apiPost,
    apiGet: apiGet,
    logout: logout
  };
  return (
    <DataDispatchContext.Provider value={allSetFun}>
      {children}
    </DataDispatchContext.Provider>
  );
}

export { DataProvider, DataDispatchContext };
