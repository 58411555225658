import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { imageBaseUrl } from "../../utils/constants";
import "./index.css";

export function DocumentUpload({
  uploadDocuments,
  setDocsUploaded,
  docsUploaded,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [audio, setAudio] = useState([]);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [disable, setDisable] = useState(true);

  const currentPage = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let completedFiles = 0;
    if (selectedFile) {
      for (let i = 0; i < selectedFile?.length; i++) {
        const formData = new FormData();
        formData.append("file", selectedFile[i]);
        await uploadDocuments(formData, selectedFile[i]?.type);
        completedFiles++;
      }
    }
    if (completedFiles == selectedFile?.length) {
      setDocsUploaded(!docsUploaded);
    }
  };

  const handleFileChange = (e) => {
    const availableAudioType = ["mp3", "m4a", "aac", "flac", "wav"];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1).toLowerCase();
      if (availableAudioType.includes(fileExt)) {
        formData.push(file);
      }
    }
    const updatedSelectedFiles = selectedFile
      ? [...selectedFile, ...formData]
      : formData;
    setAudio((prevMyFile) => [...prevMyFile, ...formData]);
    setSelectedFile(updatedSelectedFiles);
  };

  const handleFileChange1 = (e) => {
    const availableImgType = [
      "jpg",
      "jpeg",
      "png",
      "bmp",
      "heif",
      "svg",
      "heic",
    ];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1).toLowerCase();
      if (availableImgType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setImages((prev) => [...prev, ...formData]);
    const updatedSelectedFiles = selectedFile
      ? [...selectedFile, ...formData]
      : formData;
    setSelectedFile(updatedSelectedFiles);
  };
  
  const handleFileChange2 = (e) => {
    const availableVideoType = ["mp4", "avi", "hevc"];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1).toLowerCase();
      if (availableVideoType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setVideo((prev) => [...prev, ...formData]);
    const updatedSelectedFiles = selectedFile
      ? [...selectedFile, ...formData]
      : formData;
    setSelectedFile(updatedSelectedFiles);
  };

  const handleFileChange3 = (e) => {
    const availableDocType = ["docx", "pdf", "xlsx"];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1).toLowerCase();
      if (availableDocType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setDocuments((prev) => [...prev, ...formData]);
    const updatedSelectedFiles = selectedFile
      ? [...selectedFile, ...formData]
      : formData;
    setSelectedFile(updatedSelectedFiles);
  };

  const onClose = (index, name, uploadtype) => {
    const updatedFiles = [...selectedFile];
    updatedFiles.splice(index, 1);
    if (uploadtype === "Audio") {
      setAudio(updatedFiles);
    } else if (uploadtype === "Images") {
      setImages(updatedFiles);
    } else if (uploadtype === "Video") {
      setVideo(updatedFiles);
    } else {
      setDocuments(
        updatedFiles.filter((file) => file.type.includes("application"))
      );
    }
    setSelectedFile(updatedFiles);
  };

  useEffect(() => {
    if (audio?.length || images?.length || video?.length || documents?.length) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [audio, images, video, documents]);

  return (
    <div className="documentUpload">
      <span>Upload</span>
      <form onSubmit={handleSubmit}>
        {!["/upload-investigation-report", "/upload-invoice"].includes(currentPage?.pathname) && (
            <div className="documentupload-main">
              <input
                type="file"
                name="Audio"
                onChange={handleFileChange}
                className="custom-file-input"
                multiple
                accept=".mp3,.m4a,.aac,.flac,.wav"
              />
              <p className="supported-format-list">MP3, M4A, AAC, FLAC, WAV</p>
              {audio &&
                audio.map((item, i) => (
                  <div key={i} className="documentUploadassign-docs">
                    <div style={{ display: "flex" }}>
                      <div className="rejected-docs-remove">
                        <img
                          src={`${imageBaseUrl}/cross.svg`}
                          alt="cross"
                          onClick={(e) => onClose(i, item?.name, "Audio")}
                        />
                      </div>
                      <img
                        style={{ height: 20, width: 15 }}
                        src={`${imageBaseUrl}/file.png`}
                        alt="file"
                      />
                      <div className="documentUploadassign-docs-right">
                        <div className="documentUploadassign-docs-name">
                          {item?.name?.length < 11
                            ? item?.name
                            : item?.name?.slice(0, 10) + "..."}
                        </div>
                        <div className="documentUploadassign-docs-size">
                          {item?.size}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        <div className="documentupload-main">
          <input
            type="file"
            name="Images"
            onChange={handleFileChange1}
            className="Imagescustom-file-input"
            multiple
            accept=".jpg,.jpeg,.png,.bmp,.heif,.svg,.heic"
          />
          <p className="supported-format-list">
            JPG, JPEG, PNG, BMP, HEIF, SVG, HEIC
          </p>
          {images &&
            images.map((item, i) => (
              <div key={i} className="documentUploadassign-docs">
                <div style={{ display: "flex" }}>
                  <div className="rejected-docs-remove">
                    <img
                      src={`${imageBaseUrl}/cross.svg`}
                      alt="cross"
                      onClick={(e) => onClose(i, item?.name, "Images")}
                    />
                  </div>
                  <img
                    style={{ height: 20, width: 15 }}
                    src={`${imageBaseUrl}/file.png`}
                    alt="file"
                  />
                  <div className="documentUploadassign-docs-right">
                    <div className="documentUploadassign-docs-name">
                      {item?.name?.length < 11
                        ? item?.name
                        : item?.name?.slice(0, 10) + "..."}
                    </div>
                    <div className="documentUploadassign-docs-size">
                      {item?.size}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {!["/upload-investigation-report", "/upload-invoice"].includes(currentPage?.pathname) && (
            <div className="documentupload-main">
              <input
                type="file"
                name="Video"
                onChange={handleFileChange2}
                className="Videocustom-file-input"
                multiple
                accept=".mp4,.avi,.hevc"
              />
              <p className="supported-format-list">MP4, AVI, HEVC</p>
              {video &&
                video.map((item, i) => (
                  <div key={i} className="documentUploadassign-docs">
                    <div style={{ display: "flex" }}>
                      <div className="rejected-docs-remove">
                        <img
                          src={`${imageBaseUrl}/cross.svg`}
                          alt="cross"
                          onClick={(e) => onClose(i, item?.name, "Video")}
                        />
                      </div>
                      <img
                        style={{ height: 20, width: 15 }}
                        src={`${imageBaseUrl}/file.png`}
                        alt="file"
                      />
                      <div className="documentUploadassign-docs-right">
                        <div className="documentUploadassign-docs-name">
                          {item?.name?.length < 11
                            ? item?.name
                            : item?.name?.slice(0, 10) + "..."}
                        </div>
                        <div className="documentUploadassign-docs-size">
                          {item?.size}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        <div className="documentupload-main">
          <input
            type="file"
            name="Documents"
            onChange={handleFileChange3}
            className="Documentscustom-file-input"
            multiple
            accept=".docx,.pdf,.xlsx"
          />
          <p className="supported-format-list">DOCX, PDF, XLSX</p>
          {documents &&
            documents.map((item, i) => (
              <div key={i} className="documentUploadassign-docs">
                <div style={{ display: "flex" }}>
                  <div className="rejected-docs-remove">
                    <img
                      src={`${imageBaseUrl}/cross.svg`}
                      alt="cross"
                      onClick={(e) => onClose(i, item?.name, "Documents")}
                    />
                  </div>
                  <img
                    style={{ height: 20, width: 15 }}
                    src={`${imageBaseUrl}/file.png`}
                    alt="file"
                  />
                  <div className="documentUploadassign-docs-right">
                    <div className="documentUploadassign-docs-name">
                      {item?.name?.length < 11
                        ? item?.name
                        : item?.name?.slice(0, 10) + "..."}
                    </div>
                    <div className="documentUploadassign-docs-size">
                      {item?.size}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <button
          disabled={disable}
          className={
            disable ? "submitbuttonupload disable" : "submitbuttonupload"
          }
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default DocumentUpload;