import React, { memo, useEffect, useState, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { ApiDispatchContext } from "../../context/ApiContext";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectCasesDetails from "./selectors";
import Case from '../../components/case'
import './casesDetails.css';
import CommonNavbar from "../../components/CommonNavbar/index";
import reducer from "./reducer";
import { getEmployeeRole } from "../App/selectors";
import CaseCard from "./CaseCard";
import { casesUpdate } from "./actions";
import { makeSelectCardCases } from "./selectors";
import { dummyRejectedInvoices } from "../../constants/cardConstants";
import { caseCountApiCall } from "../../constants/commonFunction";
import ErrorAlert from "../../components/ErrorAlert";
import ReactPaginate from "../../components/ReactPaginate/index";
import path from "../../constants/pathConstants";
import propTypes from 'prop-types';
import { push } from 'connected-react-router';
import FilterPopUp from "../../components/FilterPopUp";
import FilterActions from '../../components/FilterActions';
import { useNavigate } from 'react-router-dom';
export function RejectedInvoices({ updateCase, getCases, employeeRole,redirectPath }) {
  useInjectReducer({ key: "casesDetails", reducer });
  const [casesCards, setCasesCards] = useState([])
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [filterHeight, setfilterHeight] = useState('auto');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalrecords, settotalrecords] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const toggle = () => setModal(!modal);
  const [open, setOpen] = useState(false);
  const [filterflag , setfilterflag] = useState(false);
  const [filterform, setfilterform] = useState({})

  const navigate = useNavigate();

  useEffect(() => {
    api.casesCount().then(result => {
      if(result.data && result.data.success){
        const tempCases = caseCountApiCall(employeeRole, dummyRejectedInvoices, result)
        setCasesCards(tempCases)
      }
      else{
        setModalTitle("Error");
        if(result.data.error && result.data.message){
          setModalBody(result.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
    let param = {
      "caseType": "rejectedInvoice",
      "page": 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }, [])

  //render items casecard
  const renderItems = useCallback(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) { 
      if (i>=0 && i < 10) {
        tempdata[i] = getCases[i];
      } 
    }
    return <CaseCard selectCards={tempdata} />
  }, [currentPage, itemsPerPage, getCases]);

  const renderItemssearch = useCallback(() =>{
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) { 
      if (i>=0 && i < 10) {
        tempdata[i] = getCases[i];
      } 
    }
    return <CaseCard selectCards={tempdata} />
  }, [getCases]);

  //on changes of page update current page
  const handlePageChange = useCallback(({ selected }) => {
    let param = {
      "caseType": "rejectedInvoice",
      "page": selected+ 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
        renderItems()
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
    setCurrentPage(selected);
  }, []);
  
  const onCasesClick = (path) => {
    navigate(path)
  }

  const filterpopupShow =() =>{
    setOpen(true)
  }

  const filterToggle = () => {
    setOpen(!open);
  }

  const submitFilterform = useCallback((data) => {
    setfilterform(data);
    const payload = {
      caseType: "rejectedInvoice",
      filter: {}
    };
    data.forEach(item => {
      if (item.label === 'Date') {
        if (item.selectedvalue1) {
          const date = new Date(item.selectedvalue1);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          payload.filter.fromDate = formattedDate;
        } else {
          payload.filter.fromDate = "";
        }
        if (item.selectedvalue2) {
          const date = new Date(item.selectedvalue2);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          payload.filter.toDate = formattedDate;
        } else {
          payload.filter.toDate = "";
        }
      } else {
        const key = item.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        const dataArray = item.value;
        for (let i = 0; i < dataArray.length; i++) {
          if (dataArray[i].value === item.selectedvalue1) {
            payload.filter[key] = {
              id: dataArray[i].id,
              column_name: dataArray[i].column_name,
              value: item.selectedvalue1
            };
            break; // Stop the loop after the first match
          } else {
            payload.filter[key] = {}
          }
        }
      }
    });
    api.getCasesDetails(payload).then(data=>{
      if(data.data && data.data.success){
        setfilterflag(true)
        updateCase(data.data.data.rows);
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
    setOpen(!open);
  }, [open]);

  const resetFilter = useCallback(() => {
    setfilterform({});
    let param = {
      "caseType": "rejectedInvoice",
      "page": 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        setfilterflag(false);
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }, []);

  const downloadReport = () => {
		// console.log("Rejected: downloadReport -> ", )
  }

  const datepickerClicked = (value) => {
    if (value === false) {
      setfilterHeight('auto')
    } else
      setfilterHeight('450px')
  } 
  
  return (
    <div data-testid="RejectedInvoicespage" className='RejectedInvoices'>
       <CommonNavbar title="Rejected Invoices" backButton={path.DASHBOARD} search={false} />
      <div className='casesDetails-card'>
        {Array.isArray(casesCards) &&
          casesCards.map((ele, index) => (
            <Case data-testid="caseinput" key={index} employeeRole={employeeRole} caseNumber={ele.caseNumber} cases={ele.cases} color={ele.color} bgColor={ele.bgColor} onClick={() => onCasesClick(ele.path)} boxShadow={ele.boxShadow} />
          ))
        }     
      </div>
      <div className="CasesDetailsfilter">
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle}  height={filterHeight ? filterHeight : 'auto'}>
        <FilterActions caseType="rejectedInvoice" onResetFilter={resetFilter} submitFilterform ={(data) => submitFilterform(data)} filterform={filterform} datepickerClicked={(value) =>datepickerClicked(value)}/>
      </FilterPopUp>)}   
      {getCases &&
          <ReactPaginate
            data-testid="reactPaginate"
            filterpopupShow={filterpopupShow}
            getCases={getCases}
            currentPage={currentPage}
            pageCount={pageCount}
            filterflag={filterflag}
            itemsPerPage={itemsPerPage}
            renderItems={filterflag ? renderItemssearch() : renderItems()}
            handlePageChange={(value) => handlePageChange(value)}
            totalrecords={totalrecords}
            caseType="rejectedInvoice"
            downloadReport={downloadReport}
          />
        }
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}
RejectedInvoices.propTypes = {
  userName: propTypes.string,
  employeeRole: propTypes.string,
  getCases : propTypes.array
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
  casesDetails: makeSelectCasesDetails(),
  getCases: makeSelectCardCases()
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
    updateCase: (data) => dispatch(casesUpdate(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo,)(RejectedInvoices);