import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectAssignedBlocksAllottedCases from "./selectors";
import reducer from "./reducer";
import GenericForms from "../../components/GenericForms";
import CommonNavbar from "../../components/CommonNavbar";
import { getUrlParameter } from "../../constants/commonFunction";
import path from "../../constants/pathConstants";
import './index.css';
import { ApiDispatchContext } from "../../context/ApiContext";
import { formData } from "./actions";
import { getEmployeeRole } from '../App/selectors';
import { setFormValue } from "./actions";
import PetitionerForm from "../../components/PetitionerForm";
import AssignBlocksFormBottom from "../../components/AssignBlocksFormBottom";
import FilterPopUp from "../../components/FilterPopUp";
import DocumentUpload from "../../components/DocumentUpload";
import AcceptButtons from '../OpenCasesDetailsPage/AcceptButtons/index';
import ErrorAlert from "../../components/ErrorAlert";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
export function AssignedBlocksAllottedCases({ assignedBlocksAllottedCases, setFormData, employeeRole,saveFormData }) {
  const parameterPermitIndex = 1;
  useInjectReducer({ key: "assignedBlocksAllottedCases", reducer });
  let caseid = Number(getUrlParameter('case'));
  let blockid = Number(getUrlParameter('blockid'));
  let subProduct = getUrlParameter('subProduct');
  const [open, setOpen] = useState(false)
  let blockname = getUrlParameter('blockname');
  let multiForm = getUrlParameter('multiform')
  const [docsUploaded,setDocsUploaded] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showUploadBtn , setshowUploadBtn] = useState(false);
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  const [showPetitionerForm,setShowPetitionerForm] = useState(false)
  const [allParameterValuesNotEmpty, setAllParameterValuesNotEmpty] = useState(false);
  const [oneParameterValuesNotEmpty, setOneParameterValuesNotEmpty] = useState(true);
  const [renamed_block_parameter , setrenamed_block_parameter] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  // const vehicleNumberRegex = /^[aA-zZ]{2}\d{2}[aA-zZ]{1,3}\d{1,4}$|^\d{2}[bB]{1}[hH]{1}\d{4}[aA-zZ]{1,2}$/;

  useEffect(() => {
    setFormData([]);
    saveFormData([]);
    window.scrollTo(0, 0);
    if(multiForm ==="true"){
      api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
        if (data.data && data.data.data) {
          setFormData(data.data.data)
          setShowPetitionerForm(true)
          let tempdata = [];
          Object.assign(tempdata, data.data.data.singleParameters);
          for (let i = 0; i < tempdata.length; i++) {
            if(tempdata[i].parameter_type === "upload"){
              setshowUploadBtn(true)
            }
          }
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          } else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    } else{
      getcreateDynamicBlockForm()
      setShowPetitionerForm(false)
    }
  }, [])

  useEffect(() => {
    setOneParameterValuesNotEmpty(handleOneFieldFilled());
    if(!showPetitionerForm && assignedBlocksAllottedCases.formData.singleParameters){
      const isAllValuesNotEmpty = assignedBlocksAllottedCases.formData.singleParameters.every(obj => {
        if(obj.parameter_type != "upload"){
        if(obj.value!==undefined){
            return  obj.value != null && obj.value !== ""; 
          }
          else{
            return obj.parameter_value != null && obj.parameter_value !== "";
          }
        }
        return true
      });
      let isAllDocNotEmpty = assignedBlocksAllottedCases.formData.singleParameters.every(obj =>{
        if(obj.parameter_type === "upload"){
          return obj.parameter_value.length;
        }
        return true
      })
      setAllParameterValuesNotEmpty(isAllValuesNotEmpty && isAllDocNotEmpty);
    }
    else if(showPetitionerForm && assignedBlocksAllottedCases.formData && (assignedBlocksAllottedCases.formData.singleParameters.length > 0 || assignedBlocksAllottedCases.formData.multipleParameters.length > 0)){
      const isAllSingleValuesNotEmpty = assignedBlocksAllottedCases.formData.singleParameters.every(obj => {
        if(obj.parameter_type != "upload"){
          return  obj.value != null && obj.value !== "" || obj.parameter_value != null && obj.parameter_value !== ""
        }
        return true
      });

      const isAllMultiValuesNotEmpty = assignedBlocksAllottedCases.formData.multipleParameters.every(arr => {
        return arr.every(obj =>obj.parameter_value != null && obj.parameter_value !== "");
      });
      let isAllDocNotEmpty = assignedBlocksAllottedCases.formData.singleParameters.every(obj =>{
        if(obj.parameter_type === "upload"){
          return obj.parameter_value.length
        }
        return true
      })
      setAllParameterValuesNotEmpty(isAllSingleValuesNotEmpty && isAllMultiValuesNotEmpty && isAllDocNotEmpty)
    }
  }, [assignedBlocksAllottedCases.formData,showPetitionerForm,open]);

  const handleOneFieldFilled = useCallback(() => {
    let fieldFilled = false; 
    assignedBlocksAllottedCases.formData.singleParameters && assignedBlocksAllottedCases.formData.singleParameters.map((item) => {
      if(item.parameter_type === "upload" && item.parameter_value && item.parameter_value.length > 0 ) {
        return fieldFilled = true;
      } 
      if(item.parameter_type !== "upload" && item.parameter_value && item.parameter_value !== null && item.parameter_value !== undefined && item.parameter_value !== "" ) {
        return fieldFilled = true;
      } 
    })
    if(showPetitionerForm && !fieldFilled) {
      assignedBlocksAllottedCases.formData.multipleParameters && assignedBlocksAllottedCases.formData.multipleParameters.map((multiData) => {
        multiData.map((item) => {
          if(item.parameter_type === "upload" && item.parameter_value && item.parameter_value.length > 0 ) {
            return fieldFilled = true;
          } 
          if(item.parameter_type !== "upload" && item.parameter_value && item.parameter_value !== null && item.parameter_value !== undefined && item.parameter_value !== "" ) {
            return fieldFilled = true;
          } 
        });
      })
    }
    return fieldFilled;
  }, [assignedBlocksAllottedCases?.formData, showPetitionerForm]);

  const filterToggle = useCallback((temprenamed_block_parameter) => {
    setrenamed_block_parameter(temprenamed_block_parameter);
    setOpen(!open);
  }, [open]);

  const redirectAssignCase = useCallback(() => {
    return path.ALLOTED_CASE_DETAILS + "?"+ btoa("case=" + caseid + "&subProduct=" + subProduct + "&redirectedFrom=AssignedBlocks");
  }, [caseid, subProduct]);

  const handleChange = useCallback((value, index) => {
    let newPetitioners = { ...assignedBlocksAllottedCases.formData }; // create a copy of the petitioners object
    newPetitioners.singleParameters[index].parameter_value = value; // update the parameter_value of the singleParameter at the given index
    setFormData(newPetitioners); // set the new state object
  }, [assignedBlocksAllottedCases?.formData]);

  const handlePetChange = useCallback((value, petIndex, petInpIndex) => {
    let newPetitioners = { ...assignedBlocksAllottedCases.formData };
    newPetitioners.multipleParameters[petIndex][petInpIndex].parameter_value = value
    setFormData(newPetitioners);
  }, [assignedBlocksAllottedCases?.formData]);
  
  const addAnother = useCallback(() => {
    let array = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData.multipleParameters))
    let lastArray = array[array.length - 1]
    let hasNonEmptyValue = false;
    for (let i = 0; i < lastArray.length; i++) {
      if (lastArray[i].parameter_value !== "") {
        hasNonEmptyValue = true;
        break;
      }
    }
    if (assignedBlocksAllottedCases.formData.multipleParameters.length != 3 && hasNonEmptyValue) {
      let tempArray = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData))
      let temp = JSON.parse(JSON.stringify(tempArray.multipleParameters[0]))
      if (temp) {
        temp.forEach(item => {
          item.parameter_value = "";
        });
      }
      tempArray.multipleParameters.push(temp)
      setFormData(tempArray)
    }
  }, [assignedBlocksAllottedCases?.formData]);

  const onPetRemove = useCallback((petIndex) => {
    setFormData({})
    let tempArray = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData))
    let temp = JSON.parse(JSON.stringify(tempArray.multipleParameters))
    temp.splice(petIndex, 1)
    tempArray.multipleParameters = temp
    setFormData(tempArray)
  }, [assignedBlocksAllottedCases?.formData]);

  const getFormDataWithSingleParams = useCallback(() => {
    const result = {};
      assignedBlocksAllottedCases.formData.singleParameters.forEach(pay => {
        if (pay.parameter_type !== "upload") {
          result[pay.parameter_name] = pay.value ? pay.value : pay.parameter_value?pay.parameter_value:null;
        }
      });
      return result;
  }, [assignedBlocksAllottedCases?.formData]);

  const getFormDataWithMultiParams = useCallback(() => {
    const result = {};
    const temp = [];
      assignedBlocksAllottedCases.formData.singleParameters.forEach(pay => {
        if (pay.parameter_type !== "upload") {
          result[pay.parameter_name] = pay.parameter_value?pay.parameter_value:null;
        }
      });
      assignedBlocksAllottedCases.formData.multipleParameters.map(item => {
        let obj = {};
        item.map(ele => {
          obj[ele.parameter_name] = ele.parameter_value?ele.parameter_value:null;
        });
        temp.push(obj);
      });
      const multipleParameters = "multipleParameters";
      result[multipleParameters] = temp;
    return result;
  }, [assignedBlocksAllottedCases?.formData]);

  const getdocumentsArray = useCallback(() => {
    let tempdata = [];
    let config = [];
    Object.assign(tempdata, assignedBlocksAllottedCases.formData.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if(tempdata[i].parameter_type === "upload"){
        config.push(tempdata[i])
      }
    }
    return config;
  }, [assignedBlocksAllottedCases?.formData]);

  const onSubmit = useCallback((saveDraft) => {
    setShowSuccessMessage(false);
    let temperror = false;
    let tempdata = [];
    Object.assign(tempdata, assignedBlocksAllottedCases.formData.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if (tempdata[i].renamed_block_parameter.toLowerCase().includes('mobile number') || tempdata[i].renamed_block_parameter.toLowerCase().includes('contact number')) {
        if (tempdata[i].parameter_value.length !== 10) {
          setModalTitle("Mobile Input Error");
          setModalBody("Invalid mobile number. Mobile number should contain 10 digits");
          toggle();
          temperror = true;
        }
      } 
      // else if (tempdata[i].block_parameters_id === 116 && tempdata[i].parameter_value.trim().length > 0) {
      //   const trimmedValue = tempdata[i].parameter_value.split(" ").join("");
      //   if (!vehicleNumberRegex.test(trimmedValue)) {
      //     setModalTitle("Vehicle Number Input Error");
      //     setModalBody("Please enter a valid vehicle number!");
      //     toggle();
      //     temperror = true;
      //     break;
      //   }
      // }
    }

    if (!temperror) {
      const payload = {
        "blockId": blockid,
        "caseId": caseid,
        "formData": !showPetitionerForm ? getFormDataWithSingleParams() : getFormDataWithMultiParams(),
        "documents": getdocumentsArray(),
        "saveDraft": saveDraft,
        "remark": assignedBlocksAllottedCases.formData && assignedBlocksAllottedCases.formData.remark ? assignedBlocksAllottedCases.formData.remark : '',
      }
      api.submitBlockFormDetails(payload).then(data => {
        if (data.data && data.data.success) {
          navigate(
              path.SUCCESS_PAGE,
              {state: {stateVal: redirectAssignCase()}}
            );
        }
        else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message)
          } else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
  }, [assignedBlocksAllottedCases?.formData, showPetitionerForm, getFormDataWithSingleParams, getFormDataWithMultiParams, getdocumentsArray, redirectAssignCase]);

  const uploadDocuments = useCallback(async(file, filetype) =>{
    setShowLoader(true);
    await api.uploadDocuments(file).then(data=>{
     if(data.data && data.data.success){
       let array = assignedBlocksAllottedCases.formData;  // let array = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData))
       let tempdata = [];
       Object.assign(tempdata, array.singleParameters);
       for (let i = 0; i < tempdata.length; i++) {
         if(tempdata[i].parameter_type === "upload" && tempdata[i].renamed_block_parameter === renamed_block_parameter){
           let newdata = {
             document_name: data.data.data.document_name,
             document_path: data.data.data.document_path,
             document_size: data.data.data.document_size,
             document_type: filetype,
           }
           tempdata[i].parameter_value.push(newdata)
         }
       }
       array.singleParameters = tempdata;
       setFormData(array);
       setOpen(!open);
       setShowLoader(false);
     } else {
       setModalTitle("Error");
       if(data.data.error && data.data.message){
         setModalBody(data.data.message)
       } else{
         setModalBody('Please try after sometime');
       }
       toggle();
       setShowLoader(false);
     }
   }).catch(err=>{
     setModalTitle("Error");
     if(err && err.response && err.response.data && err.response.data.message){
       setModalBody(err.response.data.message);
     } else {
       setModalBody('Please try after sometime');
     }
     toggle();
     setShowLoader(false);
   });
 }, [assignedBlocksAllottedCases?.formData, renamed_block_parameter, open]);

  const onRemarkChange = useCallback((value) => {
    let array = assignedBlocksAllottedCases.formData;
    let tempdata={};
    Object.assign(tempdata, array);
    tempdata.remark = value;
    setFormData(tempdata);
  }, [assignedBlocksAllottedCases?.formData])

  const onClose = useCallback((e,index,parentIndex) => {
    e.stopPropagation();
    let array = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData));
    let temp = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData.singleParameters));
    let tempindex = JSON.parse(JSON.stringify(temp[parentIndex]))
    let tempdoc = tempindex.parameter_value
    tempdoc.splice(index,1)
    tempindex.parameter_value = tempdoc
    temp[parentIndex] = tempindex
    array.singleParameters = temp
    setFormData(array)
  }, [assignedBlocksAllottedCases?.formData]);
  
  const getcreateDynamicBlockForm = useCallback((temp) => {
    api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
      if (data.data && data.data.data && data.data.data.singleParameters) {
        const arrayData = data.data.data;
        if(temp) {
          arrayData.singleParameters[parameterPermitIndex] = temp
        } else if (arrayData.singleParameters[parameterPermitIndex] && arrayData.singleParameters[parameterPermitIndex].parameter_name  && arrayData.singleParameters[parameterPermitIndex].parameter_name == "permit" && arrayData.singleParameters[parameterPermitIndex].parameter_type && arrayData.singleParameters[parameterPermitIndex].parameter_type == "radio" && arrayData.singleParameters[parameterPermitIndex].parameter_value && arrayData.singleParameters[parameterPermitIndex].parameter_value == "No") {
          arrayData.singleParameters = [arrayData.singleParameters[parameterPermitIndex]]
        }
        setFormData(arrayData)
        let tempdata = [];
        Object.assign(tempdata, data.data.data.singleParameters);
        for (let i = 0; i < tempdata.length; i++) {
          if(tempdata[i].parameter_type === "upload"){
            setshowUploadBtn(true)
          }
        }
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    })
  }, [assignedBlocksAllottedCases?.formData, caseid, blockid]);

  const handleGenFormInput = useCallback((value,index) => {
    let array = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData));
    let temp = JSON.parse(JSON.stringify(assignedBlocksAllottedCases.formData.singleParameters));
    temp[index].parameter_value = value
    if(temp[index].parameter_name == "permit" && temp[index].parameter_type == "radio" && temp[index].parameter_value == "No") {
      // removing the parameters as no is select in permit
      array.singleParameters = [temp[index]]
    setFormData(array);
  } else if(temp[index].parameter_name == "permit" && temp[index].parameter_type == "radio" && temp[index].parameter_value == "Yes"){
      // revert back to the old parameter values
      getcreateDynamicBlockForm(temp[index])
    } else {
    array.singleParameters = temp
    setFormData(array);
    }
  }, [assignedBlocksAllottedCases?.formData]);
  
  return (
    <div className="assigned-blocks allotted-block-form">
      <CommonNavbar title="Assigned Blocks" backButton={redirectAssignCase()} search={false} download={false} navbarname='AssignedBlocksAllottedCases'/>
      <div className="input-container">
        <div className="fir-gd-text">{blockname}</div>
        {!showPetitionerForm && assignedBlocksAllottedCases.formData && assignedBlocksAllottedCases.formData.singleParameters && assignedBlocksAllottedCases.formData.singleParameters.map((data,index) => {
          return <GenericForms ifRequired={true} handleGenFormInput={(value)=>handleGenFormInput(value,index)} key={data.block_parameters_id} config={data} saveFormData={saveFormData} formData={assignedBlocksAllottedCases.formData} />
        })}
      </div>
      {showPetitionerForm &&(
        <PetitionerForm 
        ifRequired={true}
        datatestid='petitionerinput'
        datatestid2='petitionerinput2'
        petitioners={assignedBlocksAllottedCases.formData}
        addAnother={addAnother} 
        onPetRemove={(petIndex)=>onPetRemove(petIndex)}
        handleChange={(value,index)=>handleChange(value,index)} 
        handlePetChange={(value,petIndex, petInpIndex) => handlePetChange(value, petIndex, petInpIndex)}
        />
        )}
      <AssignBlocksFormBottom onRemarkChange={onRemarkChange} border="1px solid #058AEE" download={false} onClose={(e,i,j)=>onClose(e,i,j)} remark={(assignedBlocksAllottedCases.formData && assignedBlocksAllottedCases.formData.remark ) ? assignedBlocksAllottedCases.formData.remark:""} docs={assignedBlocksAllottedCases.formData.singleParameters ? assignedBlocksAllottedCases.formData.singleParameters : null} filterToggle={filterToggle} showUploadBtn={false} headerNotRequired ={!showUploadBtn} showRemarks={true} />
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
      <DocumentUpload uploadDocuments={uploadDocuments}  docsUploaded={docsUploaded} setDocsUploaded={setDocsUploaded}/>
      </FilterPopUp>)}
      {showLoader && <Loader />}
      <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container">
          <AcceptButtons disabled={!allParameterValuesNotEmpty} boxShadow={allParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={allParameterValuesNotEmpty?"rgb(233, 77, 81)":"#F7BDBE"} color={allParameterValuesNotEmpty?"rgb(255, 255, 255)":"#E42125"} onClick={() => onSubmit(false)} data-testid="save-button">Submit</AcceptButtons>
          <AcceptButtons disabled={!oneParameterValuesNotEmpty} boxShadow={oneParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={oneParameterValuesNotEmpty?"#339DFF":"#ACD7FF"} color={oneParameterValuesNotEmpty?"#FFF":"#339DFF"} onClick={() => onSubmit(true)} data-testid="save-button">Save</AcceptButtons>
        </div>
      </div>
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

AssignedBlocksAllottedCases.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employeeRole: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  assignedBlocksAllottedCases: makeSelectAssignedBlocksAllottedCases(),
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setFormData: (emp) => dispatch(formData(emp)),
    saveFormData: (data) => dispatch(setFormValue(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(AssignedBlocksAllottedCases);