import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

export const CaseCard = ({ selectCards, cardClass }) => {
  return (
    <div className="reinvestigatecardmargin">
      {selectCards &&
        selectCards.map((item) => (
          <CommonCaseCard cardClass={cardClass} cardDetail={item} btn={false} />
        ))}
    </div>
  );
};

CaseCard.propTypes = {};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard);
