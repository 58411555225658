import React, { memo, useCallback, useContext, useEffect,useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectAssignedBlocksOpenCases from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import { getUrlParameter } from "../../constants/commonFunction";
import path from "../../constants/pathConstants";
import { ApiDispatchContext } from "../../context/ApiContext";
import { formData } from "./actions";
import GenericForms from "../../components/GenericForms";
import { setFormValue } from "./actions";
import { getEmployeeRole } from '../App/selectors';
import AssignBlocksFormBottom from "../../components/AssignBlocksFormBottom";
import FilterPopUp from "../../components/FilterPopUp";
import DocumentUpload from "../../components/DocumentUpload";
import './index.css';
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import ErrorAlert from "../../components/ErrorAlert";
import PetitionerForm from "../../components/PetitionerForm";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
export function AssignedBlocksOpenCases({assignedBlocksOpenCases, setFormData, employeeRole,saveFormData }) {
  useInjectReducer({ key: "assignedBlocksOpenCases", reducer });
  let caseid = Number(getUrlParameter('case'));
  const [open,setOpen] = useState(false)
  let blockid = Number(getUrlParameter('blockid'));
  let blockname = getUrlParameter('blockname');
  let multiForm = getUrlParameter('multiform')
  let subProduct = getUrlParameter('subProduct');
  const [docsUploaded,setDocsUploaded] = useState(false);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [showPetitionerForm,setShowPetitionerForm] = useState(false);
  const [allParameterValuesNotEmpty, setAllParameterValuesNotEmpty] = useState(false);
  const [oneParameterValuesNotEmpty, setOneParameterValuesNotEmpty] = useState(true);
  const [renamed_block_parameter , setrenamed_block_parameter] = useState('');
  const [showUploadBtn , setshowUploadBtn] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setFormData([]);
    saveFormData([]);
    window.scrollTo(0, 0);
      if(multiForm ==="true"){
      api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
        if (data.data && data.data.data) {
          setFormData(data.data.data);
          setShowPetitionerForm(true);
          let tempdata = [];
          Object.assign(tempdata, data.data.data.singleParameters);
          for (let i = 0; i < tempdata.length; i++) {
            if(tempdata[i].parameter_type === "upload"){
              setshowUploadBtn(true);
            }
          }
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    } else {
      api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
        if (data.data && data.data.data && data.data.data.singleParameters) {
          setFormData(data.data.data);
          let tempdata = [];
          Object.assign(tempdata, data.data.data.singleParameters);
          for (let i = 0; i < tempdata.length; i++) {
            if(tempdata[i].parameter_type === "upload"){
              setshowUploadBtn(true);
            }
          }
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
      setShowPetitionerForm(false)
    }
  }, [])


  useEffect(() => {
    setOneParameterValuesNotEmpty(handleOneFieldFilled());
    if(!showPetitionerForm && assignedBlocksOpenCases.formData.singleParameters){
    const isAllValuesNotEmpty = assignedBlocksOpenCases.formData.singleParameters.every(obj => {
      if(obj.parameter_type != "upload"){
        if(obj.value!==undefined){
          return  obj.value != null && obj.value !== "" 
        }
        else{
         return obj.parameter_value != null && obj.parameter_value !== ""
        }
      }
      return true
    });
    const isAllDocNotEmpty = assignedBlocksOpenCases.formData.singleParameters.every(obj =>{
      if(obj.parameter_type === "upload"){
        return obj.parameter_value.length > 0 ? true:false
      }
      return true
    })
    setAllParameterValuesNotEmpty(isAllValuesNotEmpty && isAllDocNotEmpty);
    } else if(showPetitionerForm && assignedBlocksOpenCases.formData && (assignedBlocksOpenCases.formData.singleParameters.length > 0 || assignedBlocksOpenCases.formData.multipleParameters.length > 0)){
      const isAllSingleValuesNotEmpty = assignedBlocksOpenCases.formData.singleParameters.every(obj => {
        if(obj.parameter_type != "upload"){
        return  obj.value != null && obj.value !== "" || obj.parameter_value != null && obj.parameter_value !== ""
        }
        return true
      });
      const isAllMultiValuesNotEmpty = assignedBlocksOpenCases.formData.multipleParameters.every(arr => {
        return arr.every(obj =>obj.parameter_value != null && obj.parameter_value !== "");
      });
      let isAllDocNotEmpty = assignedBlocksOpenCases.formData.singleParameters.every(obj =>{
        if(obj.parameter_type === "upload"){
          return obj.parameter_value.length
        }
        return true
      })
      setAllParameterValuesNotEmpty(isAllSingleValuesNotEmpty && isAllMultiValuesNotEmpty && isAllDocNotEmpty)
    }
  }, [assignedBlocksOpenCases.formData,showPetitionerForm,open]);

  const handleOneFieldFilled = useCallback(() => {
    let fieldFilled = false; 
    assignedBlocksOpenCases.formData.singleParameters && assignedBlocksOpenCases.formData.singleParameters.map((item) => {
      if(item.parameter_type === "upload" && item.parameter_value && item.parameter_value.length > 0 ) {
        return fieldFilled = true;
      } 
      if(item.parameter_type !== "upload" && item.parameter_value && item.parameter_value !== null && item.parameter_value !== undefined && item.parameter_value !== "" ) {
        return fieldFilled = true;
      } 
    })
    if(showPetitionerForm && !fieldFilled) {
      assignedBlocksOpenCases.formData.multipleParameters && assignedBlocksOpenCases.formData.multipleParameters.map((multiData) => {
        multiData.map((item) => {
          if(item.parameter_type === "upload" && item.parameter_value && item.parameter_value.length > 0 ) {
            return fieldFilled = true;
          } 
          if(item.parameter_type !== "upload" && item.parameter_value && item.parameter_value !== null && item.parameter_value !== undefined && item.parameter_value !== "" ) {
            return fieldFilled = true;
          } 
        });
      })
    }
    return fieldFilled;
  }, [assignedBlocksOpenCases?.formData, showPetitionerForm]);

  const handleChange = useCallback((value, index) => {
    let newPetitioners = { ...assignedBlocksOpenCases.formData }; // create a copy of the petitioners object
    newPetitioners.singleParameters[index].parameter_value = value; // update the parameter_value of the singleParameter at the given index
    setFormData(newPetitioners); // set the new state object
  }, [assignedBlocksOpenCases?.formData]);

  const handlePetChange = useCallback((value, petIndex, petInpIndex) => {
    let newPetitioners = { ...assignedBlocksOpenCases.formData };
    newPetitioners.multipleParameters[petIndex][petInpIndex].parameter_value = value
    setFormData(newPetitioners);
  }, [assignedBlocksOpenCases?.formData]);

  const addAnother = useCallback(() => {
    let array = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData.multipleParameters))
    let lastArray = array[array.length - 1]
    let hasNonEmptyValue = false;
    for (let i = 0; i < lastArray.length; i++) {
      if (lastArray[i].parameter_value !== "") {
        hasNonEmptyValue = true;
        break;
      }
    }
    if (assignedBlocksOpenCases.formData.multipleParameters.length != 3 && hasNonEmptyValue) {
      let tempArray = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData))
      let temp = JSON.parse(JSON.stringify(tempArray.multipleParameters[0]))
      if (temp) {
        temp.forEach(item => {
          item.parameter_value = "";
        });
      }
      tempArray.multipleParameters.push(temp)
      setFormData(tempArray)
    }
  }, [assignedBlocksOpenCases?.formData]);
  
  const redirectAssignCase = useCallback(() => {
    return path.OPEN_CASE_DETAILS + "?" + btoa("case=" + caseid+"&subProduct=" + subProduct + "&redirectedFrom=AssignedBlocks");
  }, [caseid, subProduct]);

  const filterToggle = useCallback((temprenamed_block_parameter) => {
    setrenamed_block_parameter(temprenamed_block_parameter);
    setOpen(!open);
  }, [open]);

  const getFormDataWithSingleParams = useCallback(() => {
    const result = {};
      assignedBlocksOpenCases.formData.singleParameters.forEach(pay => {
        if (pay.parameter_type !== "upload") {
          result[pay.parameter_name] = pay.value ? pay.value : pay.parameter_value?pay.parameter_value:null;
        }
      });
      return result;
  }, [assignedBlocksOpenCases?.formData]);

  const getFormDataWithMultiParams = useCallback(() => {
    const result = {};
    const temp = [];
      assignedBlocksOpenCases.formData.singleParameters.forEach(pay => {
        if (pay.parameter_type !== "upload") {
          result[pay.parameter_name] = pay.parameter_value?pay.parameter_value:null;
        }
      });
      assignedBlocksOpenCases.formData.multipleParameters.map(item => {
        let obj = {};
        item.map(ele => {
          obj[ele.parameter_name] = ele.parameter_value?ele.parameter_value:null;
        });
        temp.push(obj);
      });
      const multipleParameters = "multipleParameters";
      result[multipleParameters] = temp;
    return result;
  }, [assignedBlocksOpenCases?.formData]);

  const getdocumentsArray = useCallback(() => {
    let tempdata = [];
    let config = [];
    Object.assign(tempdata, assignedBlocksOpenCases.formData.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if(tempdata[i].parameter_type === "upload"){
        config.push(tempdata[i])
      }
    }
    return config;
  }, [assignedBlocksOpenCases?.formData]);
  
  const onSubmit = useCallback((saveDraft) => {
    setShowSuccessMessage(false);
    let temperror = false;
    let tempdata = [];
    Object.assign(tempdata, assignedBlocksOpenCases.formData.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if (tempdata[i].renamed_block_parameter.toLowerCase().includes('mobile number') || tempdata[i].renamed_block_parameter.toLowerCase().includes('contact number')) {
        if (tempdata[i].parameter_value.length !== 10) {
          setModalTitle("Mobile Input Error");
          setModalBody("Invalid mobile number. Mobile number should contain 10 digits");
          toggle();
          temperror = true;
        }
      }
    }
    if (!temperror) {
    const payload = {
        "blockId": blockid,
        "caseId": caseid,
        "formData": !showPetitionerForm ? getFormDataWithSingleParams() : getFormDataWithMultiParams(),
        "documents": getdocumentsArray(),
        "saveDraft": saveDraft,
        "remark": assignedBlocksOpenCases.formData && assignedBlocksOpenCases.formData.remark ? assignedBlocksOpenCases.formData.remark : '',
      }
      api.submitBlockFormDetails(payload).then(data => {
        if(data.data && data.data.success){
          navigate(
              path.SUCCESS_PAGE,
              {state: {stateVal: redirectAssignCase()}}
            );
        }
        else{
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err=>{
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      });
    }
  }, [assignedBlocksOpenCases?.formData, caseid, blockid, showPetitionerForm, getFormDataWithSingleParams, getFormDataWithMultiParams, getdocumentsArray]);

  const onPetRemove = useCallback((petIndex) => {
    setFormData({})
    let tempArray = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData))
    let temp = JSON.parse(JSON.stringify(tempArray.multipleParameters))
    temp.splice(petIndex, 1)
    tempArray.multipleParameters = temp
    setFormData(tempArray)
  }, [assignedBlocksOpenCases?.formData]);
  
  const uploadDocuments = useCallback(async(file, filetype) => {
    setShowLoader(true);
    await api.uploadDocuments(file).then(data=>{
     if(data.data && data.data.success){
      let array = assignedBlocksOpenCases.formData;
      let tempdata = [];
      Object.assign(tempdata, array.singleParameters);
      for (let i = 0; i < tempdata.length; i++) {
        if (tempdata[i].parameter_type === "upload" && tempdata[i].renamed_block_parameter === renamed_block_parameter) {
          let newdata = {
            document_name: data.data.data.document_name,
            document_path: data.data.data.document_path,
            document_size: data.data.data.document_size,
            document_type: filetype,
          }
          tempdata[i].parameter_value.push(newdata)
        }
      }
      array.singleParameters = tempdata;
      setFormData(array);
      setOpen(!open);
      setShowLoader(false);
     } else {
       setModalTitle("Error");
       if(data.data.error && data.data.message){
        setModalBody(data.data.message)
      }
      else{
        setModalBody('Please try after sometime');
      }
       toggle();
       setShowLoader(false);
     }
   }).catch(err=>{
     setModalTitle("Error");
     if(err && err.response && err.response.data && err.response.data.message){
      setModalBody(err.response.data.message);
    } else {
      setModalBody('Please try after sometime');
    }
     toggle();
     setShowLoader(false);
   });
  }, [assignedBlocksOpenCases?.formData, renamed_block_parameter, open]);

  const onClose = useCallback((e,index,parentIndex) => {
    e.stopPropagation();
    let array = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData));
    let temp = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData.singleParameters));
    let tempindex = JSON.parse(JSON.stringify(temp[parentIndex]))
    let tempdoc = tempindex.parameter_value
    tempdoc.splice(index,1)
    tempindex.parameter_value = tempdoc
    temp[parentIndex] = tempindex
    array.singleParameters = temp
    setFormData(array)
  }, [assignedBlocksOpenCases?.formData]);
  
  const handleGenFormInput = useCallback((value,index) => {
    let array = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData));
    let temp = JSON.parse(JSON.stringify(assignedBlocksOpenCases.formData.singleParameters));
    temp[index].parameter_value = value
    array.singleParameters = temp
    setFormData(array)
  }, [assignedBlocksOpenCases?.formData]);

  const onRemarkChange = useCallback((value) => {
    let array = assignedBlocksOpenCases.formData;
    let tempdata={};
    Object.assign(tempdata, array);
    tempdata.remark = value;
    setFormData(tempdata);
  }, [assignedBlocksOpenCases?.formData]);

  return (
    <div className="assigned-blocks AssignedBlocksOpenCases">
      <CommonNavbar title="Assigned Blocks" backButton={redirectAssignCase()} search={false} download={false} navbarname='AssignedBlocksOpenCases' />
      <div className="input-container">
        <div className="fir-gd-text">{blockname}</div>
        {!showPetitionerForm && assignedBlocksOpenCases.formData && assignedBlocksOpenCases.formData.singleParameters && assignedBlocksOpenCases.formData.singleParameters.map((data,index) => {
          return <GenericForms ifRequired={true} handleGenFormInput={(value)=>handleGenFormInput(value,index)} key={data.block_parameters_id} config={data} saveFormData={saveFormData} formcolor={'rgba(26, 178, 117, 0.1)'} formName={'AssignedblockOpencases'} formData={assignedBlocksOpenCases.formData.singleParameters} />
        })}
      </div>
      {showPetitionerForm && (
        <PetitionerForm 
        ifRequired={true}
        formcolor={'rgba(26, 178, 117, 0.1)'} 
        formName={'AssignedblockOpencases'}
        datatestid='petitionerinput'
        datatestid2='petitionerinput2'
        petitioners={assignedBlocksOpenCases.formData}
        addAnother={addAnother} 
        onPetRemove={(petIndex)=>onPetRemove(petIndex)}
        handleChange={(value,index)=>handleChange(value,index)} 
        handlePetChange={(value,petIndex, petInpIndex) => handlePetChange(value, petIndex, petInpIndex)}
        />
        )}
      <AssignBlocksFormBottom onRemarkChange={onRemarkChange} download={false} border="1px solid #1AB275" onClose={(e,i,j)=>onClose(e,i,j)} remark={(assignedBlocksOpenCases.formData && assignedBlocksOpenCases.formData.remark ) ? assignedBlocksOpenCases.formData.remark:""} docs={assignedBlocksOpenCases.formData.singleParameters ? assignedBlocksOpenCases.formData.singleParameters : null}  filterToggle={filterToggle} showUploadBtn={false} headerNotRequired ={!showUploadBtn} showRemarks={true} />
      <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container">
          <AcceptButtons disabled={!allParameterValuesNotEmpty} boxShadow={allParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={allParameterValuesNotEmpty?"rgb(233, 77, 81)":"#F7BDBE"} color={allParameterValuesNotEmpty?"rgb(255, 255, 255)":"#E42125"} onClick={() => onSubmit(false)} data-testid="save-button">Submit</AcceptButtons>
          <AcceptButtons disabled={!oneParameterValuesNotEmpty} boxShadow={oneParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={oneParameterValuesNotEmpty?"#339DFF":"#ACD7FF"} color={oneParameterValuesNotEmpty?"#FFF":"#339DFF"} onClick={() => onSubmit(true)} data-testid="save-button">Save</AcceptButtons>
        </div>
      </div>
      {open && (
        <FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
          <DocumentUpload uploadDocuments={uploadDocuments}  docsUploaded={docsUploaded} setDocsUploaded={setDocsUploaded}/>
        </FilterPopUp>
      )}
      {showLoader && <Loader />}
       {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
       <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

AssignedBlocksOpenCases.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employeeRole: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  assignedBlocksOpenCases: makeSelectAssignedBlocksOpenCases(),
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setFormData: (emp) => dispatch(formData(emp)),
    saveFormData: (data) => dispatch(setFormValue(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(AssignedBlocksOpenCases);