import React, { memo } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactPaginate from "react-paginate";
import "./index.css";
import propTypes from "prop-types";
import CasesIcons from "../CasesIcons/index";
import { getEmployeeRole } from "../../containers/App/selectors";
import { imageBaseUrl } from "../../utils/constants";
export function Paginate({
  getCases,
  currentPage,
  itemsPerPage,
  handlePageChange,
  renderItems,
  pageCount,
  totalrecords,
  filterpopupShow,
  filterflag,
  caseType,
  employeeRole,
  showSelect,
  handleShowSelect,
  downloadReport,
}) {
  const data = getCases;
  return (
    <div>
      <div className="reactpaginate-main">
        <div className="reactpaginate2">
          {!filterflag && (
            <ReactPaginate
              forcePage={currentPage}
              pageCount={
                pageCount ? pageCount : Math.ceil(data.length / itemsPerPage)
              }
              onPageChange={handlePageChange}
              data-testid="reactPaginate"
              containerClassName={"pagination-container"}
              previousLabel={"Page"}
              nextLabel={
                pageCount && (pageCount === 1 || pageCount === 0) ? (
                  ""
                ) : (
                  <img src={`${imageBaseUrl}/nextIcon.png`} alt="next" />
                )
              }
              breakLabel={".."}
              activeClassName={"active"}
              pageClassName={"page"}
              previousClassName={"previous"}
              nextClassName={"next"}
              breakClassName={"break-me"}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2} // Adjust this value to show more page numbers
              disableInitialCallback={true}
            />
          )}
        </div>
        <div className="reactpaginate3">
          <CasesIcons
            filterpopupShow={filterpopupShow}
            caseType={caseType}
            downloadReport={downloadReport}
          />
        </div>
      </div>
      {caseType === "open" &&
      employeeRole == "vendor" &&
      data &&
      data.length > 0 ? (
        <div className="multi-select-wrapper">
          <button
            data-testid="handleShowSelect"
            className="select-btn"
            onClick={() => handleShowSelect(true)}
          >
            Select
          </button>
          <div className="reactpaginate-text">
            Displaying {data.length} out of {totalrecords} cases
          </div>
          {showSelect && (
            <button
              className="cancel-btn"
              onClick={() => handleShowSelect(false)}
            >
              Cancel
            </button>
          )}
        </div>
      ) : (
        <div className="reactpaginate-text">
          Displaying {data.length} out of {totalrecords} cases
        </div>
      )}
      <div className="items-container">{renderItems}</div>
      <div className="reactpaginate-bottom">
        {!filterflag && (
          <ReactPaginate
            forcePage={currentPage}
            pageCount={
              pageCount ? pageCount : Math.ceil(data.length / itemsPerPage)
            }
            onPageChange={handlePageChange}
            data-testid="reactPaginate"
            containerClassName={"pagination-container2"}
            previousLabel={"Page"}
            nextLabel={
              pageCount && (pageCount === 1 || pageCount === 0) ? (
                ""
              ) : (
                <img src={`${imageBaseUrl}/nextIcon.png`} alt="next" />
              )
            }
            breakLabel={".."}
            activeClassName={"active"}
            pageClassName={"page"}
            previousClassName={"previous"}
            nextClassName={"next"}
            breakClassName={"break-me"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2} // Adjust this value to show more page numbers
            disableInitialCallback={true}
          />
        )}
      </div>
    </div>
  );
}
Paginate.propTypes = {
  renderItems: propTypes.object,
  downloadReport: propTypes.func,
  employeeRole: propTypes.string,
};

export const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Paginate);
